import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
// styles
import clsx from 'clsx';
import styles from './hypeboard.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
//other
import { meta } from '../../../metaData';

const Hypeboard = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "hypeboard/img1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2_1: file(relativePath: { eq: "hypeboard/img2-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2_2: file(relativePath: { eq: "hypeboard/img2-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2_3: file(relativePath: { eq: "hypeboard/img2-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2_4: file(relativePath: { eq: "hypeboard/img2-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2_5: file(relativePath: { eq: "hypeboard/img2-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "hypeboard/img3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(relativePath: { eq: "hypeboard/img4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hypeboardBg: file(relativePath: { eq: "img/bg_img_hypeboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Martech', 'USA', '2019', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Hypeboard —&#10;realtime rankings for&nbsp;Instagram"
      coverImgUrl={data.hypeboardBg.childImageSharp.fluid.srcWebp}
      subTitle="Ultimate platform for real-time monitoring of Instagram profile ranking. Main functionality: Instagram API integration, custom dashboards and charts"
      metaData={meta.hypeboard}
    >
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          The amount of Instagram users has increased, and the users can get lost while looking for
          the profiles they may be interested in. For that reason, Hyo Jing Cho, the founder of
          HYPEBOARD, came up with the idea of developing a platform that would allow users to find
          out a realtime ranking of their favourite Instagram profile with detailed statistics and
          discover new Instagram profiles based on their categories, like Actors, Musicians,
          Lifestyle, Travel, etc.
        </p>
      </div>
      <BackgroundImage
        fluid={data.img1.childImageSharp.fluid}
        className={styles.bg}
        style={{
          backgroundPosition: 'top left 35%',
        }}
      />
      <div className={itemStyles.inner}>
        <h2 className={clsx(itemStyles.title, styles.features)}>Key features</h2>
        <ul className={clsx(itemStyles.bullets, styles.bullets)}>
          <li className={styles.listItem} key="free detailed">
            Connect your Instagram profile and get free detailed statistics of your connected
            Instagram profile (Daily, Weekly and Monthly analysis).
          </li>
          <li className={styles.listItem} key="Instagram profiles">
            HYPEBOARD’s ranking algorithm provides dynamic ranking ranking lists by capturing the
            most interactive Instagram profiles.
          </li>
          <li className={styles.listItem} key="ranking list">
            Suggest your favourite Instagram profiles and categories to be added on ranking list.
          </li>
        </ul>
        <h2 className={itemStyles.title}>Case study</h2>
        <p className={itemStyles.paragraph}>
          The user’s workflow can be described in the following way:
        </p>
        <div className={styles.workflowContainer}>
          <li className={styles.workflowItem} key="img2_1">
            <Img fluid={data.img2_1.childImageSharp.fluid} className={styles.img} alt="" />
            <p className={styles.imgCaption}>
              The user signs up to the application through his Instagram account.
            </p>
          </li>
          <li className={styles.workflowItem} key="img2_2">
            <Img fluid={data.img2_2.childImageSharp.fluid} className={styles.img} alt="" />
            <p className={styles.imgCaption}>
              Having signed up to the app, the use can either choose form the given categories or
              suggest his own category.
            </p>
          </li>
          <li className={styles.workflowItem} key="img2_3">
            <Img fluid={data.img2_3.childImageSharp.fluid} className={styles.img} alt="" />
            <p className={styles.imgCaption}>
              Having chosen the category, the user will see the number of the suggested users from
              the same category.
            </p>
          </li>
          <li className={styles.workflowItem} key="img2_4">
            <Img fluid={data.img2_4.childImageSharp.fluid} className={styles.img} alt="" />
            <p className={styles.imgCaption}>
              Clicking on a particular profile, the user can see this profile’s statistics as well
              as follow the user.
            </p>
          </li>
          <li className={styles.workflowItem} key="img2_5">
            <Img fluid={data.img2_5.childImageSharp.fluid} className={styles.img} alt="" />
            <p className={styles.imgCaption}>
              On his own profile the user can see the statistics of his own profile of the category
              he’s chosen.
            </p>
          </li>
        </div>
        <p className={itemStyles.paragraph}>
          Mobile App was build with React Native and interacts with AWS platform as backend service.
        </p>
      </div>
      <div className={styles.iframeContainer}>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/E0d4hbC3K1M?enablejsapi=1&origin=https%3A%2F%2Fbrocoders.com&amp;theme=light&amp;color=white&amp;modestbranding=1&amp;rel=0&amp;autohide=2`}
          title="Hypeboard - Mobile App Presentation"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <BackgroundImage
        fluid={data.img3.childImageSharp.fluid}
        className={styles.bgIcon}
        style={{
          backgroundPosition: 'top center',
        }}
      />
      <BackgroundImage
        fluid={data.img4.childImageSharp.fluid}
        className={styles.lastImg}
        style={{
          backgroundPosition: 'top center',
        }}
      />
    </Layout>
  );
};
export default Hypeboard;
